/**
 * Basic `fetch` wrapper to send formatted plain HTTP requests off to the backend
 *
 */

import { CurrentOrgFieldsFragment } from '@/__generated__/graphql';

import { NatomaResponse, NatomaEndpoint } from './endpoints';

export interface INatomaError {
  message: string;
  type: string;
}
// TODO(zach) - this needs a type for the body, we should use the NatomaError
// class in the backend and share the type in constants, likely.
export class NatomaHTTPError extends Error {
  public statusCode: number;
  public errorBody: INatomaError;
  constructor(message: string, statusCode: number, errorBody: INatomaError) {
    super(message);

    this.statusCode = statusCode;
    this.errorBody = errorBody;
  }
}

/**
 * @classdesc A client providing a set of methods for interacting with the fetch
 * api and returning typed responses.
 *
 * @example
 * Issue a get request and receive a typed response
 *
 * ```js
 * const { currentOrg } = useContext(MeContext);
 *
 * const client = NatomaHTTPClient();
 * client.setCurrentOrg(currentOrg);
 *
 * const { redirectUrl } = client.get('/oauth/GOOGLE_WORKSPACE/auth');
 * ```
 *
 * @example
 * Issue a get request with k/v query params
 *
 * ```js
 * const { currentOrg } = useContext(MeContext);
 *
 * const client = NatomaHTTPClient();
 * client.setCurrentOrg(currentOrg);
 *
 * // Issues a request to api.<app_domain>/exampleRoute?q=something
 * client.get('/exampleRoute', {q: 'something'});
 * ```
 *
 * @example
 * Issue a post request with a body
 *
 * ```js
 * const { currentOrg } = useContext(MeContext);
 *
 * const client = NatomaHTTPClient();
 * client.setCurrentOrg(currentOrg);
 *
 * // The method imlpementation is aware of which method is being
 * // called, and contextualizes the second argument as either
 * // query params or a JSON body.
 * const response = client.post('/examplePost', {key: 'value'})
 * ```
 */
class NatomaHTTPClient {
  public url: URL;
  public orgSlug?: string;

  constructor(rootDomain: string) {
    this.url = new URL(`https://api.${rootDomain}`);
  }

  setCurrentOrg(org: CurrentOrgFieldsFragment) {
    this.orgSlug = org.slug;
  }

  get<T extends NatomaEndpoint>(
    endpoint: T,
    params?: { [key: string]: string },
    opts?: RequestInit
  ) {
    return this._call('get', endpoint, params, opts);
  }

  post<T extends NatomaEndpoint>(
    endpoint: T,
    body?: { [key: string]: string },
    opts?: RequestInit
  ) {
    return this._call('post', endpoint, body, opts);
  }

  put<T extends NatomaEndpoint>(
    endpoint: T,
    body?: { [key: string]: string },
    opts?: RequestInit
  ) {
    return this._call('put', endpoint, body, opts);
  }

  delete<T extends NatomaEndpoint>(
    endpoint: T,
    params?: { [key: string]: string },
    opts?: RequestInit
  ) {
    return this._call('delete', endpoint, params, opts);
  }

  private async _call<T extends NatomaEndpoint>(
    method: string,
    endpoint: T,
    paramsOrBody?: { [key: string]: string },
    opts?: RequestInit
  ): Promise<Extract<NatomaResponse, { endpoint: T }>['response']> {
    const fullUrl = new URL(
      `https://${this.url.host}${
        endpoint.startsWith('/') ? endpoint : `/${endpoint}`
      }`
    );

    if (method === 'get' && paramsOrBody) {
      Object.keys(paramsOrBody).forEach((key) =>
        fullUrl.searchParams.set(key, paramsOrBody[key])
      );
    }
    if (this.orgSlug) {
      fullUrl.searchParams.set('org', this.orgSlug);
    }

    const res = await fetch(fullUrl, {
      ...opts,
      method: method.toUpperCase(),
      credentials: 'include',
      mode: 'cors',
      ...(method !== 'get'
        ? {
            body: JSON.stringify(paramsOrBody),
            headers: { 'Content-Type': 'application/json' }
          }
        : {})
    });
    if (!res.ok) {
      const errors = (await res.json()) as INatomaError;
      throw new NatomaHTTPError(
        `Received HTTP ${res.status} from "${endpoint}"`,
        res.status,
        errors
      );
    }

    return res.json();
  }
}

export default NatomaHTTPClient;
