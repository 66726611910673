import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ReactNode, createContext, useMemo, useState } from 'react';

import NatomaHTTPClient from '../lib/http';
import useEnvironment from './Environment';
import { Service } from '@/__generated__/graphql';

import ToastError from './UnauthorizedToast';

interface ApiContextValues {
  apiClient?: ApolloClient<NormalizedCacheObject>;
  httpClient?: NatomaHTTPClient;
}

// the __ref string holds value like Service:[ObjectId]
type IncomingService = Service & { __ref: string };

export const ApiContext = createContext<ApiContextValues>({});

export function ApiProvider({ children }: { children: ReactNode }) {
  const { natoma } = useEnvironment();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const apiClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `https://api.${natoma.rootDomain}/graphql`,
      credentials: 'include'
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.message === 'Not authorized') {
            setErrorMessage("Your current role doesn't permit this action.");
            setShowErrorToast(true);
          }
        });
      }
      if (networkError && 'statusCode' in networkError) {
        if (networkError.statusCode === 401) {
          setErrorMessage("Your current role doesn't permit this action.");
          setShowErrorToast(true);
        }
      }
    });

    return new ApolloClient({
      // TODO: Move to env variable
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              services: {
                // only cache integrationId and isIntegration
                keyArgs: ['integrationId', 'isIntegration'],
                merge(existing = [], incoming) {
                  const merged = new Map<string, IncomingService>();

                  // Add existing items to the map
                  existing.forEach((item: IncomingService) => {
                    merged.set(item.__ref, item);
                  });
                  // Add incoming items to the map
                  incoming.forEach((item: IncomingService) => {
                    // no item yet means we just set to incoming item
                    if (!merged.has(item.__ref)) {
                      merged.set(item.__ref, item);
                    }
                    // existing item means we should build onto it
                    else {
                      const existingItem = merged.get(item.__ref);
                      merged.set(item.__ref, { ...existingItem, ...item });
                    }
                  });
                  // Convert map values to an array
                  return Array.from(merged.values());
                }
              },
              users: {
                keyArgs: ['status', 'org'],
                merge(_, incoming) {
                  return incoming;
                }
              }
            }
          },
          Service: {
            fields: {
              integration: {
                read(existing) {
                  return existing || null;
                },
                /**
                 * This is necessary since integration does not have an id to merge on.
                 * Subsequent calls to `Service.integration` with fewer fields will
                 * overwrite the cache and result in warnings and loss of data.
                 */
                merge(existing, incoming, { mergeObjects }) {
                  if (!incoming) {
                    return existing;
                  }
                  return mergeObjects(existing, incoming);
                }
              }
            }
          }
        }
      }),
      link: from([errorLink, httpLink])
    });
  }, [natoma]);

  // We can move this elsewhere, Should handle any potentially unauthed calls
  // fine since it's auth mechanism will be cookies and pulling an org out of query params.
  const httpClient = useMemo<NatomaHTTPClient>(
    () => new NatomaHTTPClient(natoma.rootDomain),
    [natoma.rootDomain]
  );

  return (
    <ApiContext.Provider value={{ apiClient, httpClient }}>
      <ApolloProvider client={apiClient}>
        {children}
        {showErrorToast && (
          <ToastError
            message={errorMessage}
            onClose={() => setShowErrorToast(false)}
          />
        )}
      </ApolloProvider>
    </ApiContext.Provider>
  );
}
