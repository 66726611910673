import React, { useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ToastContainer = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.error.main}`,
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '400px',
  width: '90%',
  zIndex: theme.zIndex.snackbar,
  background: theme.palette.background.paper,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      'linear-gradient(96.56deg, rgba(255, 255, 255, 0.08) 0%, rgba(251, 101, 20, 0.08) 49.78%, rgba(240, 68, 56, 0.08) 99.1%)',
    borderRadius: 'inherit',
    zIndex: -1
  }
}));
interface ToastErrorProps {
  message: string;
  onClose: () => void;
}

const ToastError: React.FC<ToastErrorProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <ToastContainer>
      <ErrorOutlineIcon style={{ marginRight: 8, color: 'red' }} />
      <Box flexGrow={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Action not allowed
        </Typography>
        <Typography variant="body2">{message}</Typography>
      </Box>
    </ToastContainer>
  );
};

export default ToastError;
