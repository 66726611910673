'use client';

import { Box } from '@mui/material';
import LoadingState from './LoadingState';

export default function LoadingLayout() {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      <LoadingState />
    </Box>
  );
}
