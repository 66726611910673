'use client';

import { redirect, usePathname } from 'next/navigation';
import { LoginPath, SignUpPath, InvitePath, ErrorPath } from '../lib/paths';
import { ReactNode, useContext } from 'react';
import LoadingLayoutOverlay from './LoadingLayoutOverlay';
import { MeContext } from '../providers/Me';

/**
 * Handles redirecting the page if a user is logged-in or not.
 *
 * @param props
 * @returns
 */
export default function AuthGuard({
  children,
  layout
}: {
  children: ReactNode;
  layout: 'home' | 'tenant';
}) {
  const path = usePathname();
  const isLoginOrSignUpPage =
    path === LoginPath ||
    path === SignUpPath ||
    path === InvitePath ||
    path === ErrorPath;

  const { loading, isAuthenticated, isAuthorized } = useContext(MeContext);

  const allowed = layout === 'tenant' ? isAuthorized : isAuthenticated;
  if (loading) {
    return <LoadingLayoutOverlay />;
  } else if (isLoginOrSignUpPage) {
    if (allowed) {
      redirect('/');
    } else {
      return <>{children}</>;
    }
  } else {
    if (allowed) {
      return <>{children}</>;
    } else {
      redirect(LoginPath);
    }
  }
}
