import LoadingLayout from './LoadingLayout';
import { Box } from '@mui/material';

export default function LoadingLayoutOverlay() {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0
      }}
    >
      <LoadingLayout />
    </Box>
  );
}
